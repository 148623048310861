// import { css } from '@emotion/react'
import React from 'react'
import { HeadingTertiary } from '../../components/atoms/Heading/HeadingTertiary'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEOIR } from '../../components/data/SEO'
import { LayoutNoSideNav } from '../../components/organisms/Layout/LayoutNoSideNav'

/**
 * 利用規約
 * サイドナビなし
 */
/** */
const Page = () => {
  return (
    <LayoutNoSideNav
      heading="ディスクロージャーポリシー"
      headingEnglish={ENGLISH_TITLE.DisclosurePolicy}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: 'ディスクロージャーポリシー', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8">
        <HeadingTertiary label="情報開示の基準" />
        <p className="pt-4 pb-20 pc:pb-14 leading-normal">
          当社グループは会社法、金融商品取引法、その他の諸法令及び東京証券取引所の定める上場有価証券の発行者の会社情報の適時開示等に関する規則（以下「適時開示規則」）に従って情報開示を行います。投資判断に影響を与える決定事実、発生事実が生じた場合には、適時開示規則に従って情報開示を行います。又、適時開示規則に該当しない場合でも、投資判断等で有益と思われる情報につきましても積極的に開示いたします。
        </p>
        <HeadingTertiary label="情報開示の方法" />
        <p className="pt-4 pb-20 pc:pb-14 leading-normal">
          適時開示規則に該当する情報の開示は、東京証券取引所の適時開示情報伝達システム（ＴＤｎｅｔ）にて公開しています。公開した情報は、当社ウェブサイトに速やかに掲載いたします。適時開示規則に該当しない情報についても、適時開示規則の趣旨を踏まえて、当該情報が正確かつ公平に開示するように努めます。
        </p>
        <HeadingTertiary label="インサイダー取引の未然防止" />
        <p className="pt-4 pb-20 pc:pb-14 leading-normal">
          インサイダー取引の防止を図るための社内規程を定め、グループ役職員全体への周知徹底と理解啓蒙を促進しています。又、投資判断に影響を与える重要事実に関する情報を適切に管理し、適時開示規則に則った迅速な情報開示を行うことを通して、インサイダー取引の防止を図っています。
        </p>
        <HeadingTertiary label="業績予想及び将来情報の取扱い" />
        <p className="pt-4 pb-20 pc:pb-14 leading-normal">
          当社グループの将来の見通し、計画、戦略等につきましては、過去の歴史的事実以外、その時点で入手可能な情報に基づいて判断したものです。この中には、リスクや不確定な要因も含まれており、今後、経済情勢や市場動向等の不確定要素の変動要因によって、これらの見通しと異なる可能性があることを予めご了承ください。
        </p>
        <HeadingTertiary label="沈黙期間" />
        <p className="pt-4 pb-20 pc:pb-14 leading-normal">
          当社グループは、決算情報の漏洩防止と公平性を確保するために、各四半期決算期日の翌日から各決算発表日までを沈黙期間としております。当該期間中は、決算・業績見通しに関する質問への回答や関連情報の発信その他のコメントを差し控えております。ただし、沈黙期間中に発生した業績予想と既発表の業績予想との差異が適時開示規則に該当する変動幅となることが判明した場合や、開示すべき重要事実が発生した場合は適宜情報開示を行います。
        </p>
        <HeadingTertiary label="社内体制の整備について" />
        <p className="pt-4 pb-20 pc:pb-14 leading-normal">
          ディスクロージャーポリシーを適切に運用し、適時開示規則に基づく適時適切な情報開示を実行する体制を構築し維持します。
        </p>
      </div>
    </LayoutNoSideNav>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="ディスクロージャーポリシー" url="/ir/disclosure" />
)
